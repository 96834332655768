.fp-container {
    overflow-y: auto;
    padding: 30px 15%;
    position: relative;
}
.fp-navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: none;
}
.fp-logo {
    height: 40px;
}
.fp-hello-btn {
    border-width: 2px;
    font-weight: 400;
    font-size: 18px;
    padding: 10px;
    border-radius: 290486px;
    color: #f5f9e9ff;
    background-color: transparent;
    border-color: #f5f9e9ff;
    border-style: solid;
    cursor: pointer;
}
.fp-sub-head {
    font-weight: 400;
    font-size: 24px;
}
.fp-cover-text {
    padding-top: 110px;
}