body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* SCSS HEX */
$bittersweet: #f87060ff;
$maximum-blue: #50b3e9;
$ebony: #515751ff;
$ivory: #f5f9e9ff;
$irresistible: #b54073ff;

/* SCSS Gradient */
$gradient-top: linear-gradient(0deg, #f87060ff, #4ba3c3ff, #515751ff, #f5f9e9ff, #b54073ff);
$gradient-right: linear-gradient(90deg, #f87060ff, #4ba3c3ff, #515751ff, #f5f9e9ff, #b54073ff);
$gradient-bottom: linear-gradient(180deg, #f87060ff, #4ba3c3ff, #515751ff, #f5f9e9ff, #b54073ff);
$gradient-left: linear-gradient(270deg, #f87060ff, #4ba3c3ff, #515751ff, #f5f9e9ff, #b54073ff);
$gradient-top-right: linear-gradient(45deg, #f87060ff, #4ba3c3ff, #515751ff, #f5f9e9ff, #b54073ff);
$gradient-bottom-right: linear-gradient(135deg, #f87060ff, #4ba3c3ff, #515751ff, #f5f9e9ff, #b54073ff);
$gradient-top-left: linear-gradient(225deg, #f87060ff, #4ba3c3ff, #515751ff, #f5f9e9ff, #b54073ff);
$gradient-bottom-left: linear-gradient(315deg, #f87060ff, #4ba3c3ff, #515751ff, #f5f9e9ff, #b54073ff);
$gradient-radial: radial-gradient(#f87060ff, #4ba3c3ff, #515751ff, #f5f9e9ff, #b54073ff);

.dark-background {
  background-color: $ebony;
}
.bright-text {
  color: $maximum-blue;
}
.light-text {
  color: ivory;
}
.header {
  font-size: 48px;
  font-weight: 700;
}
p {
  font-size: 18px;
}